.swiper.carousel {
    height: 100%;
    background: #473082;

    .slider-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    h1 {
        font-weight: bold;
    }
}