.min-h-120 {
    min-height: 120px;
}

.max-w-120 {
    max-width: 120px;
}

.max-w-500 {
    max-width: 500px;
}