.cursor-pointer {
    cursor: pointer;
}

@for $i from 1 through 5 {
    .z-#{$i} {
        z-index: $i;
    }
}

@for $i from 1000 through 1005 {
    .z-#{$i} {
        z-index: $i;
    }
}

.object-fit-cover {
    object-fit: cover;
}

.object-fit-contain {
    object-fit: contain !important;
}

.vertical-line {
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: $gray-300;
}

.horizontal-line {
    height: 2px;
    width: 100%;
    background-color: $gray-300;
}

.transition-base {
    @include transition($transition-base);
}

.img-draggable {
    cursor: pointer;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.no-hover {
    background-color: unset !important;
}