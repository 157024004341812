body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    user-select: none;
  }

  input {
    user-select: text;
    -webkit-user-select: text;
  }
}